import {
  FontSize,
  FontFamily,
  TextTransformOptions,
  Components,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"TradeGothicLT Standard"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"Archivo Black"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: '36px',
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"Archivo Black"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.TERTIARY_FONT]: {
    fontFamily: '"Trade Gothic LT W01 Bold Ext"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
};

const fontOverrides = {
  [Components.WYSIWYG]: {
    header2: {
      size: '48px',
    },
    header3: {
      size: '36px',
    },
    header4: {
      size: '28px',
    },
    header5: {
      size: '24px',
    },
    header6: {
      size: '24px',
    },
    header2Mobile: {
      sizeScale: 0.75,
    },
    header3Mobile: {
      sizeScale: 0.75,
    },
    header4Mobile: {
      sizeScale: 0.75,
    },
    header5Mobile: {
      sizeScale: 0.75,
    },
    header6Mobile: {
      sizeScale: 0.75,
    },
  },
  [Components.CONTENT_BLOCK_HERO]: {
    header: {
      size: '50px',
    },
    headerMobile: {
      size: '30px',
    },
  },
  [Components.CONTENT_BLOCK]: {
    headerMobile: {
      sizeScale: 0.65,
    },
  },
};

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
