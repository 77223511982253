import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#ffffff',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#666666',
    [Prop.LINK_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#ffd200',
    [Prop.BTN_BG_HOVER]: '#ffd200',
    [Prop.BTN_TXT_HOVER]: '#000000',
    [Prop.BTN_BG_ACTIVE]: '#ffd200',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#b4b4b4',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#ffffff',
    [Prop.BORDER]: '#e0e0e0',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#FFD200',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#ffd200',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#666666',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#a29b6b',
    [Prop.BTN_TXT_INACTIVE]: '#000000',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#ffffff',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#ffffff',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#ffd200',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#ffd200',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#4d4d4d',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#ffd200',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#ffd200',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#ffd200',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#ffd200',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#666666',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffd200',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#a29b6b',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#ffffff',
    [Prop.BORDER]: '#000000',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#ffffff',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#f2f2f2',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#757575',
    [Prop.LINK_TXT_HOVER]: '#000000',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#757575',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.ELEMENT]: '#ffffff',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#757575',
    [Prop.ICON]: '#ffd200',
    [Prop.ICON_HOVER]: '#000000',
  },
  [Components.DOR_LIFTS_TRAILS]: {
    [Prop.ICON_2]: '#ffd200',
    [Prop.ICON_HOVER_2]: '#000000',
    [Prop.HEADER_BG]: '#000000',
    [Prop.HEADER_TXT]: '#ffffff',
    [Prop.HEADER_ICON]: '#ffd200',
    [Prop.HEADER_BG_2]: '#ffd200',
    [Prop.HEADER_TXT_2]: '#000000',
    [Prop.HEADER_ICON_2]: '#ffffff',
    [Prop.TABLE_HEADER_BG]: '#b3b3b3',
    [Prop.TABLE_HEADER_TXT]: '#3F3F3F',
    [Prop.ODD_EVEN_OFFSET]: '#e5eded',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#ffd200',
    [Prop.NAV_BG]: '#ffd200',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_SOLID]: '#000000',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#000000',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#ffd200',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#000000',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFD200',
    [Prop.MENU_BTN_ICON]: '#000000',
    [Prop.LOGO_BG]: '#FFD200',
    [Prop.CLOSE_BTN_BG]: '#000000',
    [Prop.CLOSE_BTN_ICON]: '#FFFFFF',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_TXT]: '#000000',
    [Prop.MAIN_NAV_BORDER]: '#000000',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#ffd200',
    [Prop.DRAWER_BTN_TXT]: '#000000',
    [Prop.PROMO_BTN_BG]: '#ffd200',
    [Prop.PROMO_BTN_TXT]: '#000000',
    [Prop.SUB_NAV_BG]: '#000000',
    [Prop.SUB_NAV_TXT]: '#FFFFFF',
    [Prop.SUB_NAV_BORDER]: '#000000',
  },
  [Components.NAVIGATION_TOGGLE]: {
    [Prop.NAV_TOGGLE_TXT]: '#000000',
    [Prop.NAV_TOGGLE_ICON]: '#000000',
    [Prop.NAV_TOGGLE_TXT_SCROLLED]: '#000000',
    [Prop.NAV_TOGGLE_ICON_SCROLLED]: '#000000',
  },
};
